<template>
  <div v-loading="isPanding" v-if="current_bikoret" class="current-bikoret-active">
      <div class="current-bikoret-active-header">
          <div class="header-title">
              <p>ביקורת פעילה</p>
          </div>
          <div class="header-body">
              <p><span class="text-bold">תאריך פתיחה:</span> {{current_bikoret.created_at.toDate().toLocaleString('he')}}</p>
              <p><span class="text-bold">תאריך סיום:</span> {{current_bikoret.date_end.toDate().toLocaleString('he')}}</p>
              <p><span class="text-bold">כותרת:</span> {{current_bikoret.title}}</p>
              <p class="text-sms"><span class="text-bold">פרטי ההודעה:</span> {{current_bikoret.sms_text}}</p>
          </div>
      </div>
      <div class="current-bikoret-content">
            <template v-for="branche in branches_sorted" :key="branche.branche">
                <div v-if="branche.marked" class="branche is-checked" @click="handle_branche_marked(branche)">
                    <p>{{branche.branche}}</p>
                    <div class="branche-marked">
                        <i class="material-icons">
                            done
                        </i>
                    </div>
                </div>
                <div v-else class="branche">
                    <p>{{branche.branche}}</p>
                </div>
            </template>
    </div>
    <div v-if="show_branche_detail" class="branche-detail slide-in-left">
        <div class="branche-detail-header">
            <p>סניף</p>
            <p>{{selected_branche.branche}}</p>
        </div>
        <div class="branche-detail-images">
            <template v-for="item in selected_branche.items" :key="item.url">
                <div class="item">
                    <div class="item-title">
                        <p>{{item.item.name}}</p>
                        <p>{{item.item.barcode}}</p>
                    </div>
                    <div class="item-image">
                        <img @click="handle_click_image(item.url)" :src="item.url">
                    </div>
                </div>
            </template>
        </div>
        <div @click="show_branche_detail=false" class="close-btn">
            <p>סגור</p>
        </div>
        <div v-if="show_image_selected" class="image-pop_up">
            <div class="display-image">
                <img :src="selected_image">
                <div @click="show_image_selected=false" class="display-image-close">X</div>
            </div>
        </div>
    </div>
  </div>
  <div v-else class="no-current-bikoret-active">
      <p>לא קיימת ביקורת פעילה</p>
      <img src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fno-results_375x375.png?alt=media&token=f0d2d373-1d86-4749-9cf5-55c594b228a2">
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core'
import {projectFirestore} from '../../../../firebase/config'
export default {
    props:['bikoretId'],
    setup(props){
        const isPanding = ref(false)
        const state = ref(null)
        const current_bikoret = ref(null)
        const current_feedbacks = ref([])
        const branches_sorted = ref([])
        const selected_branche = ref(null)
        const show_branche_detail = ref(false)
        const selected_image = ref('')
        const show_image_selected = ref(false)
        

        const handle_branche_marked=(branche)=>{
            show_branche_detail.value = true
            selected_branche.value = branche
        }

        const get_active_bikort=async()=>{
            if(!props.bikoretId){
                const docs = await projectFirestore.collection('Applications').doc('2rZeQiGNNt2eU30d3HSW')
                .collection('Bikorot').where('status','==','active').get()
                if(docs.docs.length>0){
                current_bikoret.value = docs.docs.map(doc=>doc.data())[0]
                await get_current_feedbacks(current_bikoret.value.uid)
                }   
            }else{
                const doc = await projectFirestore.collection('Applications').doc('2rZeQiGNNt2eU30d3HSW')
                .collection('Bikorot').doc(props.bikoretId).get()
                current_bikoret.value = doc.data()
                await get_current_feedbacks(props.bikoretId)
            }
            
        }

        const get_current_feedbacks = async(id)=>{
            const docs = await projectFirestore.collection('Applications').doc('2rZeQiGNNt2eU30d3HSW')
            .collection('Feedback').where('bikoret_uid','==',id).get()
            if(docs.docs.length>0){
                current_feedbacks.value = docs.docs.map(doc=>doc.data())
            }

        }

        const mark_which_branche_has_feedback = ()=>{
            current_bikoret.value.selected_branches.forEach(branche=>{
                const index  = current_feedbacks.value.findIndex(feedback=>feedback.branche==branche)
                if(index!=-1){
                    branches_sorted.value.push({
                        ...current_feedbacks.value[index],
                        marked:true
                    })
                }
                else{
                    branches_sorted.value.push({
                        branche:branche,
                        marked:false
                    })
                }
            })
        }

        const handle_click_image = (url)=>{
            selected_image.value = url
            show_image_selected.value = true
        }

        onMounted(async()=>{
            isPanding.value = true
            await get_active_bikort()
            if(current_bikoret.value){
                mark_which_branche_has_feedback()
            }
            isPanding.value = false
        })

        
        return{show_image_selected,handle_click_image,selected_image,show_branche_detail,selected_branche,state,isPanding,branches_sorted,current_bikoret,handle_branche_marked}
    }
}
</script>

<style scoped>
    .current-bikoret-active{
        position: relative;
        width: 100%;
        height: 100%;
        
    }
    .no-current-bikoret-active{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;
        font-size: 20px;
    }
    .branche-detail{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: var(--secondary);
        border-radius: 10px;
        overflow:hidden;
        overflow-y: auto;
        color: #fff;
        z-index: 2;
    }
    .image-pop_up{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .display-image{
        position: relative;
        width: 50%;
        height: 50%;
    }
    .display-image-close{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 0;
        left: 0;
        background: crimson;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 50%;
        font-weight: bold;
    }
    .image-pop_up img{
        max-height: 100%;
        max-width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .close-btn{
        position: absolute;
        top: 5px;
        left: 5px;
        width: 50px;
        height: 30px;
        background: crimson;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 20px;
        cursor: pointer;
    }
    .branche-detail-header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 25px;
        width: 100%;
        height: 10%;        
    }
    .branche-detail-images{
        width: 100%;
        height: 90%;
        display: grid;
        grid-template-columns: 100%;
        grid-auto-rows: 200px;
        grid-gap: 5px;
        overflow:hidden;
        overflow-y: auto;
    }
    .item{
        border-radius: 5px;
        width: 100%;
        height: 200px;
        background: var(--purple);
        color: #fff;
        font-size: 15px;
    }
    .item-title{
        width: 100%;
        height: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .item-image{
        width: 100%;
        height: 80%;
        text-align: center;
        background: var(--secondary);
    }
    .item-image img{
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
    }
    .current-bikoret-active-header{
        width: 100%;
        height: 40%;
        background: var(--secondary);
        color:#fff
    }
    .header-title{
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 25px;
    }
    .header-body{
        padding: 10px;
        width: 100%;
        height: 90%;
        line-height: 40px;
        font-size: 18px;
    }
    .text-sms{
        line-height:normal;
    }
    .header-body p:nth-child(odd){
        background: rgba(0, 0, 0, 0.2);
        width: max-content;
        border-radius: 5px;
    }
    .text-bold{
        font-weight: 500;
    }
    .current-bikoret-content{
        margin-top: 5px;
        width: 100%;
        height: calc(60% - 5px);
        display: grid;
        grid-template-columns: 100%;
        grid-auto-rows: 50px;
        grid-gap: 2px;
        overflow:hidden;
        overflow-y: auto;
    }
    .branche{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 18px;
        border-radius: 5px;

    }
    .branche-marked{
        position: absolute;
        top: 20%;
        right: 20px;
        width: 30px;
        height: 30px;
        background: lightgreen;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .is-checked{
        cursor: pointer;
    }
    .branche:nth-child(odd){
        background: var(--secondary);
    }
    .slide-in-left {
	-webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @-webkit-keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
                transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
                transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
    }




</style>