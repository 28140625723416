<template>
    <div class="manage-bikoret-shilut">
        <div class="manage-tool-bar">
           <div @click="state='ביקורת חדשה'" class="ad-btn">
                <p>ביקורת חדשה</p>
            </div>
           <div @click="state='ביקורת נוכחית'" class="ad-btn">
                <p>ביקורת נוכחית</p>
            </div>
           <div @click="state='ניהול ביקורות'" class="ad-btn">
                <p>ביקורות קודמות</p>
            </div>
        </div>

        <div class="state-manager-admin">
            <NewReview v-if="state=='ביקורת חדשה'" />
            <BikorotManagement v-if="state=='ניהול ביקורות'"/>
            <CurrentBikoretActive v-if="state=='ביקורת נוכחית'" />
        </div>

    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import NewReview from './NewReview.vue'
import BikorotManagement from './bikorot_management/BikorotManagement.vue'
import CurrentBikoretActive from './bikorot_management/CurrentBikoretActive.vue'
export default {
components:{NewReview,BikorotManagement,CurrentBikoretActive},
setup(){
    const state = ref(null)

    return{
        state
    }
}
}
</script>

<style scoped>
.manage-bikoret-shilut{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}
.manage-tool-bar{
    width: 100%;
    height: 60px;
    background: var(--secondary);
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 5px;
}
.ad-btn{
    margin-right: 5px;
    width: fit-content;
    padding: 5px;
    background: var(--purple);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 5px;
    text-shadow: 0 1px 3px black;
    font-weight: 400;
    cursor: pointer;
}
.state-manager-admin{
    width: 100%;
    height: calc(100% - 65px);
    overflow-y: auto;
}
</style>