<template>
  <div class="new-review">
    <div class="field">
        <p class="field-title">כותרת</p>
        <el-input v-model="values.title" placeholder="כותרת" />
    </div>
    <div class="field">
        <p>תאריך ביקורת: {{values.created_at.toLocaleString('he')}}</p>
        <p>יוזם ביקורת: {{current_user.display_name}}</p>
    </div>
    <div class="field">
        <p class="field-title">הודעת SMS</p>
        <el-input
        v-model="values.sms_text"
        :rows="3"
        type="textarea"
        placeholder="Please input"
        maxlength="130"
    />
    </div>
    <template v-for="(item,index) in values.items" :key="index" >
        <div class="field item">
            <el-input v-model="values.items[index].name" style="width:38%" placeholder="שם" />
            <el-input v-model="values.items[index].barcode" style="width:38%" placeholder="ברקוד" />
            <el-button @click="handle_remove_item(index)" v-if="index!=values.items.length-1"  style="width:20%" type="danger">הסר</el-button>
            <el-button v-if="index==values.items.length-1" @click="handle_add_item" style="width:20%" type="success">הוסף</el-button>
        </div>
    </template>
    <div class="field dates-finish">
          <p class="field-title">תאריך ושעת סיום</p>
          <input v-model="values.date_end" style="width:100%;" type="datetime-local">
    </div>
    <div class="field">
          <p class="field-title">בחר סניף/ים <span @click="handle_choosed_all_branches" class="all_btn">בחר הכל</span></p>
          <el-select style="width:100%;" v-model="values.selected_branches" multiple placeholder="בחר סניף/ים">
            <el-option v-for="branche in branches" :key="branche" :value="branche"/>
        </el-select>
    </div>
    <div class="field">
        <el-button @click="handle_submit" style="width:100%;" type="success">סיים</el-button>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, onMounted } from '@vue/runtime-core'
import {projectFirestore, projectFunctions} from '../../../firebase/config'
import store from '../../../store'
import { slide_pop_error, slide_pop_successs,alert } from '../../../Methods/Msgs';
import router from '../../../router'

export default {
    setup(){
        const error_msg = ref(false)
        const branches = ref([])

        const current_user = ref(computed(()=>{
            return store.getters.user
        }))

        const handle_add_item = ()=>{
            values.value.items.push({
                name:'',
                barcode:''
            })
        }

        const handle_remove_item =(index)=>{
            values.value.items.splice(index,1)
        }

        const values = ref({
            created_at: new Date(),
            title:'',
            sms_text:'',
            items:[{
                name:'',
                barcode:''
            }],
            date_end: null,
            selected_branches:[],
            phones:[],
            uid: null,
            status: ''
        })

        const handle_choosed_all_branches = ()=>{
            values.value.selected_branches = [...branches.value]
        }

        const validation = ()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'title':
                        if(!value){
                            error_msg.value = 'נא להזין כותרת'
                            return false
                        }
                        break;
                    case 'sms_text':
                        if(!value){
                            error_msg.value = 'נא להזין הודעת SMS'
                            return false
                        }
                        break;
                    case 'items':
                        const index = values.value.items.findIndex(item=>!item.name || !item.barcode)
                        if(index!=-1){
                            error_msg.value = 'נא להזין פריטים בצורה תקינה'
                            return false
                        }
                        break;
                    case 'date_end':
                        if(!value){
                            error_msg.value = 'נא להזין תאריך ושעת סיום'
                            return false
                        }
                        break;
                    case 'selected_branches':
                        if(value.length==0){
                            error_msg.value = 'נא לבחור סניף אחד לפחות'
                            return false
                        }
                        break;
                }
            }
            return true
        }
        const handle_submit = async () =>{
            if(!validation()){
                slide_pop_error(error_msg.value)
            }else{
                 values.value.date_end = new Date(values.value.date_end)
                //await get_branche_phon_from_db();
                values.value.phones.push('+972544221254');
                values.value.status = 'active';
                projectFunctions.httpsCallable("send_mms")({
                    phone_array: values.value.phones,
                    sms_text: values.value.sms_text + ' למעבר לאפליקצייה לחץ כאן: https://rosmannext.web.app/bikoretshilut '
                })
                await set_data_to_db();
                alert('success','הצלחה','הביקורת נשלחה בהצלחה')
                .then(()=>{
                    router.push('/')
                })
            }
        }

        const get_branche_phon_from_db = async()=>{
            values.value.selected_branches.forEach(async branche => {
                const _phone = (await projectFirestore.collection('Users').where('branche','==',branche).limit(1).get()).docs.map(doc => doc.data().phone)
                if(_phone && _phone[0]){
                    values.value.phones.push('+972' + _phone[0]);
                }else{
                    slide_pop_error("חסר מספר נייד: " + branche);
                }
            })
        }

        const set_data_to_db = async()=>{
            const doc = projectFirestore.collection('Applications').doc('2rZeQiGNNt2eU30d3HSW').collection('Bikorot').doc()
            values.value.uid= doc.id;
            await doc.set(values.value)
            setTimeout(() => {
                slide_pop_successs("הביקורת הוקמה בהצלחה!")
                setTimeout(()=>{
                    slide_pop_successs("שולח SMS לסניפים הרלוונטיים")
                },1000)
            }, 500);
        }

        onMounted(async()=>{
            await get_branches_list_from_db()
        })

        const get_branches_list_from_db = async()=>{
            const docs = await projectFirestore.collection('Shivuk').doc('Shivuk')
            .collection('Branches').get()

            branches.value = docs.docs.map(doc=>doc.data().name)
        }
        return{
            handle_add_item,
            handle_remove_item,
            handle_choosed_all_branches,
            handle_submit,
            values,
            branches,current_user,
            error_msg
        }
    }    
}
</script>

<style scoped>
    .new-review{
        width: 100%;
        height: fit-content;
    }
    .field{
        width: 100%;
        height: fit-content;
        color: #fff;
        margin-top: 5px;
    }
    .field-title{
        width: 100%;
        height: fit-content;
        padding: 5px 0 5px 0;
        text-align: start;
        font-size: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .field.item{
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }

    input[type="datetime-local"] {
        height: 35px;
        border: 0.5px solid lightgrey;
        border-radius: 3px;
    }
    input[type="datetime-local"]:hover {
        border: 1.1px solid lightblue;
    }
    input[type="datetime-local"]:focus {
        outline: none;
        border: 1.1px solid lightblue;
    }

    .all_btn{
        background: darkmagenta;
        padding: 2px;
        border-radius: 5px;
        cursor: pointer;
        margin-right: 10px;
    }
</style>